import { createRouter, createMemoryHistory } from "vue-router";
import Home from './components/Home.vue';

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/rsvp",
    component: Home,
  },
  {
    path: "/locations",
    component: Home,
  }
];
const router = createRouter({
  history: createMemoryHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }
  },
})

export default router;