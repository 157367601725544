<template>
  <div :class="mtype" class="font">
     <p>{{ msg }}</p>
  </div>
</template>

<script>
export default {
  name: 'chatBubble',
  props: {
    msg: String,
    mtype: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div{
  flex: 0 1 auto;
  margin-bottom: 20px;
  min-height: 50px auto;
  max-width: 60%;
  align-content: center;
}
p{
  margin: 0px;
  padding:0px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.guest{
  background: #f9dcdd;
  color: #3a6090;
  align-self: flex-end;
  border-top-left-radius: 25px;
  border-top-right-radius: 0px;
  animation: fadeinright 0.8s;
}
.our {
  background: #3a6090;
  color: #FFFFff;
  align-self: flex-start;
  border-top-left-radius: 0px;
  border-top-right-radius: 25px;
  animation: fadeinleft 0.8s;
}
.font{
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
}
.message{
  width: auto;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  
}

@keyframes fadeinleft {
    from { opacity: 0; transform: translateX(-20%); }
    to { opacity: 1; transform: translateX(0); }
}

@keyframes fadeinright {
    from { opacity: 0; transform: translateX(20%); }
    to { opacity: 1; transform: translateX(0); }
}
</style>
