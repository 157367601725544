<template>
  <div class="logowrap">
    <div class="header">
      <svg class="logo" :class="{animation: verified}" version="1.1" xmlns="http://www.w3.org/2000/svg"  xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
          width="233" height="202" viewBox="-0.103 -0.496 233 202" enable-background="new -0.103 -0.496 233 202" xml:space="preserve">
        <defs>
        </defs>
        <g>
          <path fill="#395F8F" d="M97.92,74.117c2-11-6.5-14.5-13.5-21.5c-8.5-8.5-15.5-16.5-27.5-20s-31-4-40.5,5c-11,10.5-5,24.5-8.5,37.5
            c-3,13.5-16.5,38.5,0.5,45.5c17.5,8,41,0.5,56.5-9c-14,9.5-31,17.5-33,36.5c-1.5,12.5,7,29,17,35.5c5,3.5,10.5,4,16,6.5
            c7.5,4,14.5,8.5,23,10c9,1.5,23.5,0,32-3c12-5,31.5-21,31-35c-0.5-7.5-4.5-11.5-8.5-17c-5.5-6.5-4.5-11.5-7-19.5
            c1,7.5,4.5,13.5,8.5,20c4.5,7,5,7.5,14,6.5c11-1,20.5,1,31-2c14-3.5,31-2,39.5-16c8-13,0-25.5-2-38.501c-1.5-10-0.5-20.5-1.5-30
            c-1.5-11-7-10.5-17-11c-9.5-0.5-18.5-1.5-27.5,3.5c-8.5,4.5-15.5,13-24.5,16.5c-2.5-20,62.5-61.5,14-73c-12.5-3-28.5-1-41.5-0.5
            c-13.5,0.5-26.5-1-37,9c-17,16-4.5,37,2,54.5"/>
        </g>
        <g>
          <path fill="#F9DCDD" d="M137.13,95.114c-7.473-7.471-18.205-15.06-29.504-12.675c-9.226,1.947-16.969,13.474-11.496,21.676
            c1.001,1.5,5,1,6.5,2c3.5,2,4,4,6.5,6c5.5,6,7,6.5,15,3.5c3.5-1.5,12-4.5,15-7.5C144.13,103.615,142.13,99.614,137.13,95.114z"/>
        </g>
        <g>
          <path fill="#FFE605" d="M126.397,100.114c-0.5-8-17-13-20.5-5c-5.5,13,28.5,16,20,2.5"/>
        </g>
      </svg>
      <div>
        <h1 id="title">Johanna & Felix</h1>
        <p id="subtitle">10. Mai 2025</p>
      </div>

    </div>
      <div class="abfrage" v-if="verified===false">
        <h1>Hallo {{ vorname }}</h1>
        <p>um sicherzustellen, dass nur unsere Gäste Zugang haben, bitten wir dich, deinen Namen zur Verifizierung einzugeben.</p>
        <div  @keydown.enter="checkGuest" id="wrapinput">
          <input v-model="vorname" id="Name" placeholder="Vorname" />
          <input v-model="nachname" id="Name" placeholder="Nachname" />
          <input type="submit" hidden />
          <div class="check" @click="checkGuest">
            Prüfen
          </div>
        </div>
      </div>
    <div class="hello" v-if="verified">
      <Nav />
      <Chat id="RSVP"/>
      <Locations  id="locations"/>
    </div>
  </div>
</template>

<script>
import Chat from './chat.vue'
import Nav from './Nav.vue'
import Locations from './Locations.vue';
import axios from 'axios'

export default {
  name: 'HomeSite',
  components: {
    Chat,
    Nav,
    Locations
  },
  props: {
    msg: String
  },
  created() {
     
  },
  data() {
    return {
      vorname: '',
      nachname:'',
      guests: [{Vorname: 'Felix', Nachname: 'Schmidt'}],
      verified: false
    }
  },
  methods: { 
    fetchGuests() {
      axios.get('../../getGuests.php')
        .then(response => {
          this.guests = response.data;
        })
        .catch(error => {
          console.error('Error fetching guests:', error);
        });
    },
    checkGuest(){
      if(Array.isArray(this.guests)){
        const lowercaseGuests = this.guests;
        const exists = lowercaseGuests.some(guest =>  guest.Vorname.toLowerCase() === this.vorname.toLowerCase() &&
                                                      guest.Nachname.toLowerCase() === this.nachname.toLowerCase() );
        console.log(lowercaseGuests);
        if(exists){
          this.verified = true;
          console.log(this.verified)
        }
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.header{
  top: 0px;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100px;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: #ffffff;
}
#title{
  margin: 0px;
}
#subtitle{
  margin-top:-10px;
  margin: 0px;
  font-size: 9pt;
  letter-spacing: 3pt;
}
.abfrage{
  position: relative;
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  z-index:10;
  font-family: "Poppins", serif;
  font-weight: 500;
  font-style: normal;
  color: #FFFFFF;
  padding-left: 30px;
  padding-right: 30px;

}
#wrapinput{
  display: flex;
  flex-direction: column;
  align-items: center;
}
input{
  height: 30px;
  width: 200px; 
  border-radius: 25px;
  border: solid 1px #FFFFFF;
  background: none;
  margin: 10px;
  color: #FFFFFF;
  text-align: center;
}
.check{
  height: 30px;
  width: 200px;
  border-radius: 25px;
  border: solid 1px #f9dcdd;
  background: #f9dcdd;
  margin: 10px;
  color: #3a6090;
  justify-content: center;
  align-items: center;
  display: flex;
}
.logowrap{
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-self: start;
}
.logo{
  z-index: 2;
  margin-top: 0px;
  height: 100px;
  margin: 0px;
  image-rendering: crisp-edges;
  transform-origin: center;
  transform: scale(2000%) translateY(-12px) translateX(10px) rotate(90deg);
}
.animation{
  animation: flower 3s;
  animation-fill-mode: forwards;
  margin: 0px;
  width: auto;
}
@keyframes flower {
  0% {
    transform: scale(2000%) translateY(-28px) rotate(90deg);
  }
  100% {
    transform: scale(70%) translateY(0px) rotate(0deg) translateX(0px);
  }
}



input::placeholder {
      color: white; /* Platzhalterfarbe auf weiß setzen */
      opacity: 1; /* Sicherstellen, dass die Farbe vollständig deckend ist */
      font-family: "Poppins", serif;
  font-weight: 500;
    
    }
    input::-webkit-input-placeholder { /* Chrome, Safari, Opera */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      color: white;
      opacity: 1;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
    input::-ms-input-placeholder { /* Edge */
      color: white;
      font-family: "Poppins", serif;
  font-weight: 500;
    }
</style>
