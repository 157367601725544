<template>
  <div class="wrap">
    <router-link :to="{ path: '/rsvp', hash: '#RSVP' }">
      <h1>
        Rückmeldung
      </h1>
    </router-link>
    <router-link :to="{ path: '/locations', hash: '#locations' }">
      <h1>
        Locations
      </h1>
    </router-link>
    <h1>
      Trauzeugen
    </h1>
    <h1>
      Jo & Felix
    </h1>   
  </div>
</template>

<script>


export default {
  name: 'NavSite',
  components: {
  },
  props: {
    msg: String
  },
  created() {
     
  },
  data() {
    return {
    }
  },
  methods: { 
    navigate() {
        const current = this.$router.currentRoute.fullPath;
        const href = this.$refs.link.$el.getAttribute('href');
        if (current === href) {
          location.href = this.to.hash;
        }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#flower1{
  position: absolute;
  transform: scale(200%);
  right: -190px;
  bottom: -0px;
}
.wrap{
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 100vw;
}

h1{
  font-size: 30pt;
}
a {
  text-decoration: none;
  color: inherit;
   }
</style>
